import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { setViewsResults, setWorkbooks} from "../../redux/sidebar/sidebar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../../../src/static/css/style.css"
import { fetchDahboard } from '../../config/api/thunkapi';
import { toast } from 'react-toastify';
const Dashboard = () => {
  const [selectedViewUrl, setSelectedViewUrl] = useState("");
  const [selectedView, setSelectedView] = useState();
  const [selectedWorkBookName, setSelectedWorkBookName] = useState("");
  const {parsedData,jwtToken} = useLocalStorage();
  const dispatch = useDispatch();
  const viewResult = useSelector((state) => state.sidebar.viewsResults);
  const workbooks = useSelector((state) => state.sidebar.workbooks);
  const { id } = useParams();
  const { data,error } = useSelector((state) => state.dashboard);
   if(error){
    toast.error(error)
   }  
  
  if (data && Object.keys(data).length > 0) {
    dispatch(setViewsResults(data?.viewsResults));
    dispatch(setWorkbooks(data?.workbooks));
  }  

  const getDashboard = async () => {
    dispatch(fetchDahboard(parsedData));
  }

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    if (id && viewResult.length > 0 && workbooks.length > 0) {
      let selectedView = null;
      let workbookId = '';
      let viewUrlName = '';

      for (const result of viewResult) {
        const hasView = result?.views?.find((view) => view.id === id);
        if (hasView) {
          workbookId = result.workbookId;
          viewUrlName = hasView.viewUrlName;
          selectedView = hasView;
          break;
        }
      }

      if (selectedView) {
        setSelectedView(selectedView);

        const selectedWorkBook = workbooks.find(
          (workbook) => workbook.id === workbookId
        );

        if (selectedWorkBook) {
          setSelectedWorkBookName(selectedWorkBook?.contentUrl);

          const baseUrl = selectedWorkBook.webpageUrl.substring(
            0,
            selectedWorkBook.webpageUrl.lastIndexOf('/workbooks/')
          );
          const viewUrl = `${baseUrl}/views/${selectedWorkBook.contentUrl}/${viewUrlName}`;
          setSelectedViewUrl(viewUrl);
          console.log({ viewUrl });
        }
      }
    }
  }, [id, viewResult, workbooks]);
  return (
    <>
      <PageHeader
        ghost
        title={selectedWorkBookName}
        subTitle={selectedView?.name || ""}
      />
      <Main>
        <Row gutter={25}>
          <Col lg={24} xs={24}>
            <Cards headless>
               <div
                style={{
                  minHeight: "calc(100vh - 220px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
               >
                {selectedViewUrl && (
                  <tableau-viz
                    id="tableauViz"
                    className="tableau-viz"
                    hide-tabs={false}
                    touch-optimize={false}
                    disable-url-actions={false}
                    debug={false}
                    src={selectedViewUrl}
                    device="Desktop"
                    toolbar="bottom"
                    token={jwtToken}
                    style={{backgroundColor:"rgb(244, 245, 247)",height:"100%" , width:"100%" }}
                  />
                )}
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Dashboard;
