import axios from 'axios';
import { API } from './apiConfig';
import { apiCallFailure, apiCallStart, apiCallSuccess } from '../../redux/users/dataReducer';
import { apiDashboardCallFailure, apiDashboardCallStart, apiDashboardCallSuccess } from '../../redux/dashboard/dashboardReducer';


export const Adminlogin = (credentials) => async (dispatch) => {
  dispatch(apiCallStart());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API.auth.login}`,
      credentials
    );
    dispatch(apiCallSuccess(response.data)); 
  } catch (error) {
    dispatch(apiCallFailure(error?.response?.data?.message || 'An error occurred'));
  }
};

export const fetchDahboard = (parsedData) => async (dispatch) => {
  dispatch(apiDashboardCallStart());
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API.dashboard.token}?token=${parsedData?.token}&siteId=${parsedData?.site?.id}`
    );
    dispatch(apiDashboardCallSuccess(response?.data?.data)); 
  } catch (error) {
    dispatch(apiDashboardCallFailure(error?.response?.data?.message || 'An error occurred'));
  }
};
